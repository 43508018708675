import React, { Suspense, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  BrowserRouter as Router,
} from 'react-router-dom';
import SuspenseSpin from 'components/SuspenseSpin';
import './App.css';
import BillingPDF from 'pages/dashboard/billing/BillingPDF';
import lazy from 'utils/lazyWithRetry';
import { I18nextProvider, withNamespaces } from 'react-i18next';
import i18n from './i18n';
import { ConfigProvider } from 'antd';
import faIR from './i18n/antd/fa_IR';
import enUS from 'antd/es/locale/en_US';
import moment from 'moment';
import 'moment/dist/locale/fa';
moment.locale('fa');
const LogingedIndexPage = lazy(() => import('pages/LogingedIndexPage'));
const delegatedPage = lazy(() => import('pages/authentication/delegatedPage'));
const MultiStepActivateDevice = lazy(
  () => import('pages/authentication/MultiStepActivateDevice'),
);
const AuthIndexPage = lazy(() => import('pages/AuthIndexPage'));

const AzureADRedirectPage = lazy(
  () => import('pages/dashboard/AzureADRedirect'),
);

const RedirectLoginPage = lazy(() => import('pages/RedirecrLoginPage'));
const App = () => {
  const [locale, setLocale] = useState(enUS);
  useEffect(() => {
    document.dir = i18n.dir();
    if (i18n.dir() === 'rtl') {
      document.documentElement.className = 'lng-rtl';
      setLocale(faIR);
    } else {
      document.documentElement.className = '';
      setLocale(enUS);
    }
  }, [i18n.language]);
  return (
    <I18nextProvider i18n={i18n}>
      <ConfigProvider direction={i18n.dir()} locale={locale}>
        <Router>
          <Suspense fallback={<SuspenseSpin />}>
            <Switch>
              <Route exact path={`/auth/login`} component={RedirectLoginPage} />
              <Route exact path={`/auth/delegated`} component={delegatedPage} />
              <Route path="/auth" component={AuthIndexPage} />
              <Route
                exact
                path={`/dashboard/sync/azureAD`}
                component={AzureADRedirectPage}
              />
              <Route
                exact
                path="/dashboard/bill/:id/pdf"
                component={BillingPDF}
              />
              <Route
                exact
                path={`/hardware/set`}
                component={MultiStepActivateDevice}
              />
              <Route
                exact
                path={`/accessKey/set`}
                component={MultiStepActivateDevice}
              />
              <Route
                exact
                path={`/hybridphone/set`}
                component={MultiStepActivateDevice}
              />
              <Route path="/" component={LogingedIndexPage} />
              <Redirect to="/dashboard" />
            </Switch>
          </Suspense>
        </Router>
      </ConfigProvider>
    </I18nextProvider>
  );
};

export default withNamespaces()(App);
