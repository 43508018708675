import { action } from 'typesafe-actions';

export const DevicesActionTypes = {
  GET_AZURE_AD_PC_REQUEST: '@@groups/GET_AZURE_AD_REQUEST',
  GET_ALL_DEVICES_REQUEST: '@@groups/GET_ALL_DEVICES_REQUEST',
  POST_AZURE_AD_PC_REQUEST: '@@groups/POST_AZURE_AD_REQUEST',
  GET_ALL_PC_REQUEST: '@@groups/GET_ALL_PC_REQUEST',
  GET_ALL_SECURITY_KEY_DEVICES: '@@devices/GET_ALL_SECURITY_KEY_DEVICES',
  GET_ALL_SECURITY_KEY_DEVICES_OF_USER:
    '@@devices/GET_ALL_SECURITY_KEY_DEVICES_OF_USER',
  DELETE_SINGLE_DEVICE_REQUEST: '@@devices/DELETE_SINGLE_DEVICE_REQUEST',
  DELETE_MANY_DEVICE_REQUEST: 'devices/DELETE_MANY_DEVICE_REQUEST',
  DELETE_ALL_USER_DEVICES_REQUEST: '@@devices/DELETE_ALL_USER_DEVICES_REQUEST',
  SUSPEND_SINGLE_DEVICE_REQUEST: '@@devices/SUSPEND_SINGLE_DEVICE_REQUEST',
  SUSPEND_MANY_DEVICE_REQUEST: '@devices/SUSPEND_MANY_DEVICE_REQUEST',
  ACTIVATE_SINGLE_DEVICE_REQUEST: '@@devices/ACTIVATE_SINGLE_DEVICE_REQUEST',
  ACTIVATE_MANY_DEVICE_REQUEST: '@@devices/ACTIVATE_MANY_DEVICE_REQUEST',
  GET_NOT_SHARED_DEVICE_REQUEST: '@@devices/GET_NOT_SHARED_DEVICE_REQUEST',
  PUT_SHARED_DEVICE_REQUEST: '@@devices/PUT_SHARED_DEVICE_REQUEST',
  DELETE_SHARED_DEVICE_REQUEST: '@@devices/DELETE_SHARED_DEVICE_REQUEST',
  PUT_OFFLINE_SECURITY_DEVICE_REQUEST:
    '@@devices/PUT_OFFLINE_SECURITY_DEVICE_REQUEST',
  PUT_OFFLINE_USER_SECURITY_DEVICE_REQUEST:
    '@@devices/PUT_OFFLINE_USER_SECURITY_DEVICE_REQUEST',
  PUT_OFFLINE_AZURE_AD_DEVICE_REQUEST:
    '@@devices/PUT_OFFLINE_AZURE_AD_DEVICE_REQUEST',
  PUT_VERIFICATION_DEVICES_REQUEST:
    '@@devices/PUT_VERIFICATION_DEVICES_REQUEST',
  GET_FINGERPRINT_ENROLLMENT: '@@devices/GET_FINGERPRINT_ENROLLMENT',
  GET_ALL_ACTIVATED_CARD: '@@devices/GET_ALL_ACTIVATED_CARD',
  POST_ASSIGN_BULK_SK: '@@devices/POST_ASSIGN_BULK_SK',
  GET_DEVICE_INFO: '@@device/GET_DEVICE_INFO',
  GET_DEVICE_FULL_INFO: '@@device/GET_DEVICE_FULL_INFO',
  GET_DEVICE_CREDENTIAL: '@@device/GET_DEVICE_CREDENTIAL',
  GET_CONNECTED_TOKEN: '@@device/GET_CONNECTED_TOKEN',
  PUT_DEVICE_DEFAULT_USER_REQUEST: '@@devices/PUT_DEVICE_DEFAULT_USER_REQUEST',
  POST_SET_CARD_PIN_REQUEST: '@@devices/POST_SET_CARD_PIN_REQUEST',
};

export const getAzureAD_PCsRequest = (data) =>
  action(DevicesActionTypes.GET_AZURE_AD_PC_REQUEST, data);
export const getAllDevicesRequest = (data) =>
  action(DevicesActionTypes.GET_ALL_DEVICES_REQUEST, data);
export const postAzureAD_PCsRequest = (data) =>
  action(DevicesActionTypes.POST_AZURE_AD_PC_REQUEST, data);
export const getAll_PCsRequest = (data) =>
  action(DevicesActionTypes.GET_ALL_PC_REQUEST, data);
export const getAllSecurityKeyDevicesRequest = (data) =>
  action(DevicesActionTypes.GET_ALL_SECURITY_KEY_DEVICES, data);
export const deleteSingleDeviceRequest = (data) =>
  action(DevicesActionTypes.DELETE_SINGLE_DEVICE_REQUEST, data);
export const deleteManyDeviceRequest = (data) =>
  action(DevicesActionTypes.DELETE_MANY_DEVICE_REQUEST, data);
export const deleteAllUserDevicesRequest = (data) =>
  action(DevicesActionTypes.DELETE_ALL_USER_DEVICES_REQUEST, data);
export const suspendSingleDeviceRequest = (data) =>
  action(DevicesActionTypes.SUSPEND_SINGLE_DEVICE_REQUEST, data);
export const suspendManyDeviceRequest = (data) =>
  action(DevicesActionTypes.SUSPEND_MANY_DEVICE_REQUEST, data);
export const activateSingleDeviceRequest = (data) =>
  action(DevicesActionTypes.ACTIVATE_SINGLE_DEVICE_REQUEST, data);
export const activateManyDeviceRequest = (data) =>
  action(DevicesActionTypes.ACTIVATE_MANY_DEVICE_REQUEST, data);
export const getAllSecurityKeyDevicesOfUser = (data) =>
  action(DevicesActionTypes.GET_ALL_SECURITY_KEY_DEVICES_OF_USER, data);
export const getNotSharedDevice = (data) =>
  action(DevicesActionTypes.GET_NOT_SHARED_DEVICE_REQUEST, data);
export const putSharedDevice = (data) =>
  action(DevicesActionTypes.PUT_SHARED_DEVICE_REQUEST, data);
export const deleteSharedDeviceRequest = (data) =>
  action(DevicesActionTypes.DELETE_SHARED_DEVICE_REQUEST, data);
export const putOfflineSecurityDevice = (data) =>
  action(DevicesActionTypes.PUT_OFFLINE_SECURITY_DEVICE_REQUEST, data);
export const putOfflineAzureADDevice = (data) =>
  action(DevicesActionTypes.PUT_OFFLINE_AZURE_AD_DEVICE_REQUEST, data);
export const putVerificationDevices = (data) =>
  action(DevicesActionTypes.PUT_VERIFICATION_DEVICES_REQUEST, data);
export const getFingerprintEnrollment = (data) =>
  action(DevicesActionTypes.GET_FINGERPRINT_ENROLLMENT, data);
export const getAllActivatedCardRequest = (data) =>
  action(DevicesActionTypes.GET_ALL_ACTIVATED_CARD, data);
export const postAssignBulkSKRequest = (data) =>
  action(DevicesActionTypes.POST_ASSIGN_BULK_SK, data);
export const getDeviceInfo = (data) =>
  action(DevicesActionTypes.GET_DEVICE_INFO, data);
export const getDeviceFullInfo = (data) =>
  action(DevicesActionTypes.GET_DEVICE_FULL_INFO, data);
export const getDeviceCredential = (data) =>
  action(DevicesActionTypes.GET_DEVICE_CREDENTIAL, data);
export const getConnectedToken = (data) =>
  action(DevicesActionTypes.GET_CONNECTED_TOKEN, data);
export const putDeviceDefaultUserRequest = (data) =>
  action(DevicesActionTypes.PUT_DEVICE_DEFAULT_USER_REQUEST, data);
export const postSetCardPinRequest = (data) =>
  action(DevicesActionTypes.POST_SET_CARD_PIN_REQUEST, data);
