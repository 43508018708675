// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import devicesApis from 'apis/deviceApis';
// actions
import { DevicesActionTypes } from 'actions/devicesActions';

export function* handleGetAzureAD_PCs({ payload }) {
  try {
    const response = yield call(devicesApis.getAzureAD_PCs, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetAllDevices({ payload }) {
  try {
    const response = yield call(devicesApis.getAllDevices, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostAzureAD_PCs({ payload }) {
  try {
    const response = yield call(devicesApis.postAzureAD_PCs, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetALLPCs({ payload }) {
  try {
    const response = yield call(devicesApis.getAllPCs, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlegetAllSecurityKeyDevicesRequest({ payload }) {
  try {
    const response = yield call(
      devicesApis.getAllSecurityKeyDevicesRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleDeleteSingleDeviceRequest({ payload }) {
  try {
    const response = yield call(devicesApis.deleteSingleDeviceRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleDeleteManyDeviceRequest({ payload }) {
  try {
    const response = yield call(devicesApis.deleteManyDeviceRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleSuspendSingleDeviceRequest({ payload }) {
  try {
    const response = yield call(
      devicesApis.suspendSingleDeviceRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleSuspendManyDeviceRequest({ payload }) {
  try {
    const response = yield call(devicesApis.suspendManyDeviceRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleActivateSingleDeviceRequest({ payload }) {
  try {
    const response = yield call(
      devicesApis.activateSingleDeviceRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleActivateManyDeviceRequest({ payload }) {
  try {
    const response = yield call(devicesApis.activateManyDeviceRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetAllSecurityKeyDevicesOfUserRequest({ payload }) {
  try {
    const response = yield call(
      devicesApis.getAllSecurityKeyDevicesOfUserRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetNotSharedDeviceRequest({ payload }) {
  try {
    const response = yield call(devicesApis.getNotSharedDeviceRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePutShareDeviceRequest({ payload }) {
  try {
    const response = yield call(devicesApis.putshareDeviceRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleDeleteSharedDeviceRequest({ payload }) {
  try {
    const response = yield call(devicesApis.deleteSharedDeviceRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePutOfflineSecurityDevice({ payload }) {
  try {
    const response = yield call(devicesApis.putOfflineSecurityDevice, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutOfflineAzureADDevice({ payload }) {
  try {
    const response = yield call(devicesApis.putOfflineAzureADDevice, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutVerifivationDevices({ payload }) {
  try {
    const response = yield call(devicesApis.putVerificationDevices, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetFingerprintEnrollment({ payload }) {
  try {
    const response = yield call(devicesApis.getFingerprintEnrollment, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleDeleteAllUserDevices({ payload }) {
  try {
    const response = yield call(
      devicesApis.deleteAllUserDevicesRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlegetActivatedCardRequest({ payload }) {
  try {
    const response = yield call(devicesApis.getAllActivatedCard, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostAssignBulkSKRequest({ payload }) {
  try {
    const response = yield call(devicesApis.postAssignBulkSK, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetDeviceInfoRequest({ payload }) {
  try {
    const response = yield call(devicesApis.getDeviceInfo, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetDeviceFullInfoRequest({ payload }) {
  try {
    const response = yield call(devicesApis.getDeviceFullInfo, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetDeviceCredentialRequest({ payload }) {
  try {
    const response = yield call(devicesApis.getDeviceCredential, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetConnectedTokenRequest({ payload }) {
  try {
    const response = yield call(devicesApis.getConnectedToken, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutDeviceDefaultUserRequest({ payload }) {
  try {
    const response = yield call(devicesApis.putDeviceDefaultUser, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostSetCardPinRequest({ payload }) {
  try {
    const response = yield call(devicesApis.postSetCardPinRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
/** ***********************************************************
 ************************************************************* */

function* devicesSaga() {
  yield all([
    yield takeEvery(
      DevicesActionTypes.GET_AZURE_AD_PC_REQUEST,
      handleGetAzureAD_PCs,
    ),
    yield takeEvery(
      DevicesActionTypes.GET_ALL_DEVICES_REQUEST,
      handleGetAllDevices,
    ),
    yield takeEvery(
      DevicesActionTypes.POST_AZURE_AD_PC_REQUEST,
      handlePostAzureAD_PCs,
    ),
    yield takeEvery(DevicesActionTypes.GET_ALL_PC_REQUEST, handleGetALLPCs),
    yield takeEvery(
      DevicesActionTypes.GET_ALL_SECURITY_KEY_DEVICES,
      handlegetAllSecurityKeyDevicesRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.DELETE_SINGLE_DEVICE_REQUEST,
      handleDeleteSingleDeviceRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.DELETE_MANY_DEVICE_REQUEST,
      handleDeleteManyDeviceRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.SUSPEND_SINGLE_DEVICE_REQUEST,
      handleSuspendSingleDeviceRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.SUSPEND_MANY_DEVICE_REQUEST,
      handleSuspendManyDeviceRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.ACTIVATE_SINGLE_DEVICE_REQUEST,
      handleActivateSingleDeviceRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.ACTIVATE_MANY_DEVICE_REQUEST,
      handleActivateManyDeviceRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.GET_ALL_SECURITY_KEY_DEVICES_OF_USER,
      handleGetAllSecurityKeyDevicesOfUserRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.GET_NOT_SHARED_DEVICE_REQUEST,
      handleGetNotSharedDeviceRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.PUT_SHARED_DEVICE_REQUEST,
      handlePutShareDeviceRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.DELETE_SHARED_DEVICE_REQUEST,
      handleDeleteSharedDeviceRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.PUT_OFFLINE_SECURITY_DEVICE_REQUEST,
      handlePutOfflineSecurityDevice,
    ),
    yield takeEvery(
      DevicesActionTypes.PUT_OFFLINE_AZURE_AD_DEVICE_REQUEST,
      handlePutOfflineAzureADDevice,
    ),
    yield takeEvery(
      DevicesActionTypes.PUT_VERIFICATION_DEVICES_REQUEST,
      handlePutVerifivationDevices,
    ),
    yield takeEvery(
      DevicesActionTypes.GET_FINGERPRINT_ENROLLMENT,
      handleGetFingerprintEnrollment,
    ),
    yield takeEvery(
      DevicesActionTypes.DELETE_ALL_USER_DEVICES_REQUEST,
      handleDeleteAllUserDevices,
    ),
    yield takeEvery(
      DevicesActionTypes.GET_ALL_ACTIVATED_CARD,
      handlegetActivatedCardRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.POST_ASSIGN_BULK_SK,
      handlePostAssignBulkSKRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.GET_DEVICE_INFO,
      handleGetDeviceInfoRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.GET_DEVICE_FULL_INFO,
      handleGetDeviceFullInfoRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.GET_DEVICE_CREDENTIAL,
      handleGetDeviceCredentialRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.GET_CONNECTED_TOKEN,
      handleGetConnectedTokenRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.PUT_DEVICE_DEFAULT_USER_REQUEST,
      handlePutDeviceDefaultUserRequest,
    ),
    yield takeEvery(
      DevicesActionTypes.POST_SET_CARD_PIN_REQUEST,
      handlePostSetCardPinRequest,
    ),
  ]);
}

export default devicesSaga;
