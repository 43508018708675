import Dispatch from './client';

const urls = {
  azureAD_PCs: (method) => ({
    url: `/administrator/aad/devices`,
    method,
  }),
  getAllPCs: {
    method: 'get',
    url: '/administrator/devices/allDevices',
  },
  getAllDevices: {
    method: 'get',
    url: '/administrator/devices',
  },
  getAzureAD_connected: {
    method: 'get',
    url: '/administrator/devices/AzureAD',
  },
  getAllSecurityKeyDevicesRequest: {
    url: '/administrator/securityDevice',
    method: 'get',
  },
  deleteSingleDevice: (_id) => ({
    url: `/administrator/securityDevice/${_id}`,
    method: 'delete',
  }),
  deleteManyDevice: {
    url: `/administrator/securityDevice/`,
    method: 'delete',
  },
  suspendSingleDevice: (_id) => ({
    url: `/administrator/securityDevice/suspend/${_id}`,
    method: 'put',
  }),
  suspendManyDevice: {
    url: '/administrator/securityDevice/suspend',
    method: 'put',
  },
  activateSingleDevice: (_id) => ({
    url: `/administrator/securityDevice/activate/${_id}`,
    method: 'put',
  }),
  activateManyDevice: {
    url: '/administrator/securityDevice/activate',
    method: 'put',
  },
  getAllSecurityKeyDevicesOfUser: (tokenId) => ({
    url: `/administrator/securityDevice/user/${tokenId}`,
    method: 'get',
  }),
  getNotSharedDevice: (tokenId) => ({
    url: `/administrator/securityDevice/user/${tokenId}/list-device-can-share`,
    method: 'get',
  }),
  putSharedDevice: (tokenId) => ({
    url: `/administrator/securityDevice/user/${tokenId}/share-device`,
    method: 'put',
  }),
  deleteSharedDevice: (deviceId, tokenId) => ({
    url: `/administrator/securityDevice/user/shared/${deviceId}/${tokenId}`,
    method: 'delete',
  }),
  deleteAllUserDevices: (userId) => ({
    url: `/administrator/tokens/${userId}/devices`,
    method: 'delete',
  }),
  putOfflineSecurityDevice: {
    url: `/administrator/securityDevice/offline`,
    method: 'put',
  },
  putOfflineAzureADDevice: {
    url: `/administrator/devices/offline`,
    method: 'put',
  },
  putVerificationDevices: {
    url: `/administrator/securityDevice/verification`,
    method: 'put',
  },
  getFingerprintEnrollment: (challenge) => ({
    url: `/administrator/securityDevice/fingerprint/enrollment/${challenge}`,
    method: 'get',
  }),
  getAllActivatedCard: {
    url: '/administrator/securityDevice/activated-card',
    method: 'get',
  },
  postAssignBulkSK: {
    url: '/administrator/securityDevice/bulk-card-assignment/notify',
    method: 'post',
  },
  getDeviceInfo: (id) => ({
    url: `/administrator/securityDevice/${id}`,
    method: 'get',
  }),
  getDeviceFullInfo: (id) => ({
    url: `/administrator/securityDevice/${id}/info`,
    method: 'get',
  }),
  getDeviceCredential: (id) => ({
    url: `/administrator/securityDevice/credentials/${id}`,
    method: 'get',
  }),
  getConnectedToken: (id) => ({
    url: `/administrator/securityDevice/${id}/connected-tokens`,
    method: 'get',
  }),
  putDeviceDefaultUser: {
    url: `/administrator/securityDevice/default-user`,
    method: 'put',
  },
  postSetCardPin: {
    url: `/auth/setId`,
    method: 'post',
  },
};

function api() {
  return {
    getAzureAD_PCs: (data) =>
      Dispatch(urls.getAzureAD_connected, data.params, data.payload),
    getAllDevices: (data) =>
      Dispatch(urls.getAllDevices, data.params, data.payload),
    postAzureAD_PCs: (data) =>
      Dispatch(urls.azureAD_PCs('post'), data.params, data.payload),
    getAllPCs: (data) => Dispatch(urls.getAllPCs, data.params, data.payload),
    getAllSecurityKeyDevicesRequest: (data) =>
      Dispatch(urls.getAllSecurityKeyDevicesRequest, data.params, data.payload),
    deleteSingleDeviceRequest: (data) =>
      Dispatch(
        urls.deleteSingleDevice(data.params._id),
        data.params,
        data.payload,
      ),
    deleteManyDeviceRequest: (data) =>
      Dispatch(urls.deleteManyDevice, data.params, data.payload),
    deleteAllUserDevicesRequest: (data) =>
      Dispatch(urls.deleteAllUserDevices(data.params.userId)),
    suspendSingleDeviceRequest: (data) =>
      Dispatch(urls.suspendSingleDevice(data.params._id), {}, data.payload),
    suspendManyDeviceRequest: (data) =>
      Dispatch(urls.suspendManyDevice, data.params, data.payload),
    activateSingleDeviceRequest: (data) =>
      Dispatch(urls.activateSingleDevice(data.params._id), {}, data.payload),
    activateManyDeviceRequest: (data) =>
      Dispatch(urls.activateManyDevice, data.params, data.payload),
    getAllSecurityKeyDevicesOfUserRequest: (data) =>
      Dispatch(
        urls.getAllSecurityKeyDevicesOfUser(data.params._id),
        data.params,
        data.payload,
      ),
    getNotSharedDeviceRequest: (data) =>
      Dispatch(urls.getNotSharedDevice(data.user), data.params, data.payload),
    putshareDeviceRequest: (data) =>
      Dispatch(urls.putSharedDevice(data.user), data.params, data.payload),
    deleteSharedDeviceRequest: (data) =>
      Dispatch(
        urls.deleteSharedDevice(data.params.deviceId, data.params.tokenId),
        data.params,
        data.payload,
      ),
    putOfflineSecurityDevice: (data) =>
      Dispatch(urls.putOfflineSecurityDevice, {}, data.payload),
    putOfflineAzureADDevice: (data) =>
      Dispatch(urls.putOfflineAzureADDevice, {}, data.payload),
    putVerificationDevices: (data) =>
      Dispatch(urls.putVerificationDevices, {}, data.payload),
    getFingerprintEnrollment: (data) =>
      Dispatch(
        urls.getFingerprintEnrollment(data.params.challenge),
        data.params,
        data.payload,
      ),
    getAllActivatedCard: (data) =>
      Dispatch(urls.getAllActivatedCard, data.params, data.payload),
    postAssignBulkSK: (data) =>
      Dispatch(urls.postAssignBulkSK, data.params, data.payload),
    getDeviceInfo: (data) =>
      Dispatch(urls.getDeviceInfo(data.id), data.params, data.payload),
    getDeviceFullInfo: (data) => Dispatch(urls.getDeviceFullInfo(data.id)),
    getDeviceCredential: (data) =>
      Dispatch(urls.getDeviceCredential(data.id), data.params, data.payload),
    getConnectedToken: (data) =>
      Dispatch(urls.getConnectedToken(data.id), data.params, data.payload),
    putDeviceDefaultUser: (data) =>
      Dispatch(urls.putDeviceDefaultUser, {}, data.payload),
    postSetCardPinRequest: (data) =>
      Dispatch(urls.postSetCardPin, {}, data.payload),
  };
}

export default api();
